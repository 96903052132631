.draftJsEmojiPlugin__emoji__2oqBk {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
  background-color: #cce7ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
  background-color: #e6f3ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}
.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: scale(0);
}
